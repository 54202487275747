import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Agent } from '../../api/models/agent';
import { LoaderService } from '../../_services/loader.service';
import { ModalService } from '../../_services/modal.service';
import { AgentService } from '../../api/services/agent.service';
import { MyToastrService } from '../../_services/toastr.service';
import { CommonService } from '../../_services/common.services';
import { BaseRequestService } from '../../_services/base.service';
import { ConfirmDialogService } from '../../_services/confirmdialog.service';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { MatSidenav } from '@angular/material/sidenav';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { CompanyService } from 'src/app/api/services';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { CompanySharedService } from 'src/app/_services/company-shared.service';
import { SearchPipe } from 'src/app/_filters/app.filter.pipe';

@Component({
  selector: 'app-agents',
  templateUrl: './agents.component.html',
  styleUrls: ['./agents.component.scss'],
})
export class AgentsComponent implements OnInit {
  @ViewChild('migrate', { static: true }) migrate: MatSidenav;
  public companyCtrl: FormControl = new FormControl();
  public companyFilterCtrl: FormControl = new FormControl();
  public filteredCompanies: ReplaySubject<any> = new ReplaySubject<any>(1);
  public searching = false;
  protected onDestroySearch = new Subject<void>();
  @Input() currentCompany: any;
  @Input() selectedAgent: any = 1;
  @Input() isDeprecated: any;
  @Input() mode: any;
  totalProbe = 0;
  currentAsset: any;
  agenTableOptions: any;
  addAgentWidth = 600;
  addlogsWidth = 1080;
  fromMaxStartDate = new Date();
  fromMinEndDate: any = null;
  fromMaxEndDate: any = null;
  currentAgent: any;
  reason: any = '';
  agent: any = {
    name: '',
    agent_type: 1,
    agentcompanyRef: { id: '', name: '' },
  };
  probeScanData: any = {
    scantype: '',
    companyid: '',
    agent_ids: []
  }
  isLoading = false;
  agentsCurrentPage = 0;
  assetId: any;
  step = 0;
  sslData: any = [];
  sslScanData: any;
  agentsView = 'agents';
  filterQuery: any;
  colFilterQuery: any;
  colFilterCols: any = [];
  agentStatus: any;
  agentId: any;
  externalOS: any;
  externalAType: any;
  hName: any;
  aIP: any;
  aVersion: any;
  cName: any;
  companiesList: any = [];
  enumData = ['', 'LinuxX86', 'Windows', 'Darwin', 'LinuxArm'];
  agentEnum = [
    '',
    'Probe',
    'Lightweight Agent',
    'Lightweight Agent Installed',
    'External Scan Agent',
  ];

  scanTypeList = [
    {name: 'Full Scan', value: 1},
    {name: 'Asset Scan', value: 2},
    {name: 'Active Directory Scan', value: 5},
    {name: 'SNMP Scan', value: 6},
    {name: 'Firewall Scan', value: 8},
    {name: 'PII Scan', value: 9},
  ];

  scanTypeLightWeightList = [
    {name: 'LightWeight Agent Scan', value: 7},
    {name: 'PII Scan', value: 9},
  ];
  companyScanWidth = 600;
  companyScanWidthPerformance = 900;
  migrationData: any = {
    source_companyid: '',
    destination_companyid: '',
  };
  allComp: any;
  currentEventLogs: any = {
    name: '',
    agentId: '',
    startDate: null,
    endDate: null,
  };
  agentLogData: any = { name: '', log: '' };
  allAgent: any = [];
  isGlobalAction = false;

  constructor(
    private loaderService: LoaderService,
    private toast: MyToastrService,
    private aS: AuthenticationService,
    private baseService: BaseRequestService,
    private confirmDialog: ConfirmDialogService,
    public modalService: ModalService,
    private agentService: AgentService,
    public commonService: CommonService,
    private companyService: CompanyService,
    public cs: CompanySharedService,
    private searchPipe: SearchPipe,
  ) {
    this.cs.agentDeprecateCloseEVE.subscribe((value: any) => {
      this.getAgents();
    });
    // this.cs.dateFormatChange.subscribe((value: any) => {
    //   console.log("*************************",value);
    // });

    this.agenTableOptions = {
      columns: [
        {
          header: 'Online',
          columnDef: 'agentStatus',
          filter: '',
          cell: '(element: any) => `${element.agentStatus}`',
          order: 0,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          width: '50px',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          selectFilter: true,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          statusicon: true,
          success: true,
          failure: false,
          successIconPath: '/assets/images/devices/online.svg',
          successToolTip: 'Online',
          failureIconPath: '/assets/images/devices/offline.svg',
          failureToolTip: 'Offline',
          selectFilterArr: [
            { name: 'Online', id: 'AgentOnline', value: true },
            { name: 'Offline', id: 'AgentOffline', value: false },
          ],
        },
        {
          header: 'Host Name',
          id: 'AgenthostName',
          columnDef: 'host_name',
          filter: '',
          cell: '(element: any) => `${element.host_name}`',
          order: 1,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'Version',
          id: 'AgentVersion',
          columnDef: 'version',
          filter: '',
          cell: '(element: any) => `${element.version}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'Agent Type',
          columnDef: 'agent_type',
          cell: '(element: any) => `${element.agent_type}`',
          order: 3,
          filter: '',
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          selectFilter: true,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          selectFilterArr: [
            { name: 'Probe', id: 'AgentProbe', value: 1 },
            {
              name: 'Lightweight Agent Installed',
              id: 'AgentLightweightInstalled',
              value: 3,
            },
          ],
        },
        {
          header: 'IP',
          id: 'AgentIp',
          columnDef: 'ip',
          filter: '',
          cell: '(element: any) => `${element.ip}`',
          order: 4,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'OS Type',
          columnDef: 'ostype',
          filter: '',
          cell: '(element: any) => `${element.ostype}`',
          order: 5,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          selectFilter: true,
          iscolumnSearch: true,
          selectFilterArr: [
            { name: 'Linux', id: 'AgentLinux', value: 1 },
            { name: 'Windows', id: 'AgentWindows', value: 2 },
            { name: 'Mac', id: 'AgentMac', value: 3 },
            { name: 'LinuxArm', id: 'AgentLinuxArm', value: 4 },
          ],
        },
        {
          header: 'Installed on',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 6,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Last Scanned Time',
          columnDef: 'lastscannedtime',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.lastscannedtime}`',
          order: 7,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Last PII Scan Time',
          columnDef: 'lastpiiscan_time',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.lastpiiscan_time}`',
          order: 7,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Last Ping Time',
          columnDef: 'lastConnectedTime',
          filter: 'epochToDate',
          cell: '(element: any) => `${element.lastConnectedTime}`',
          order: 7,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'ID',
          columnDef: '_id',
          cell: '(element: any) => `${element._id}`',
          order: 9,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
      ],
      sortOptions: { active: 'u', direction: 'desc' },
      faClass: 'Agent',
      _pageData: [],
      tableOptions: {
        id: 'agents',
        title: 'Probe Agents',
        isServerSide: true,
        selectText: 'agent(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: this.selectedAgent !== 4,
        showColFilter: true,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Uninstall',
            icon: 'remove_circle_outline',
            callback: 'detailFn',
            isGlobal: true,
          },
          {
            text: 'Delete',
            icon: 'delete_forever',
            callback: 'detailFn',
            isGlobal: true,
          },
          {
            text: 'Fetch Event Logs',
            icon: 'find_in_page',
            callback: 'downloadFn',
            isGlobal: false,
          },
          {
            text: 'Agent Update Info',
            icon: 'change_circle',
            callback: 'agentFn',
            isGlobal: false,
          },
        ],
        pagination: true,
        pageOptions: [ 5, 10, 25, 50, 100],
        pageSize: 10,
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: true,
        add: false,
        columnSearch: true,
        compareData: false,
      },
    };
  }

  setStep(index: number): void {
    this.step = index;
  }

  ngOnInit(): void {
    this.agenTableOptions.pageData = [];
    this.getAgents();
    this.companyFilterCtrl.valueChanges
      .pipe(debounceTime(300), takeUntil(this.onDestroySearch))
      .subscribe(() => {
        this.filterCompanies();
      });
  }

  private filterCompanies(): void {
    if (!this.companiesList) {
      return;
    }
    // get the search keyword
    let search = this.companyFilterCtrl.value;
    if (!search) {
      this.companiesList = Object.assign([], this.allComp);
      this.filteredCompanies.next(this.companiesList.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.getCompanies(search);
  }

  scanCompanyNow(type: any): void {
    // @ts-ignore
    this.loaderService.display(true, `Initiating probe scan}`);
    this.baseService
      .doRequest(
        `/api/agent/imaws/scan`,
        'post',
        this.probeScanData
      ).subscribe((result: any) => {
      this.loaderService.display(false);
      (type === 'probe') ? this.modalService.close('probeScan') : this.modalService.close('lightWeightScan');
      if (result[0]) {
        this.toast.sToast('success', result[1]);
        (type === 'lightweight' && this.probeScanData.scantype === 7) ? null : this.cs.jobsViewEVE.next({});
        this.getAgents();
      } else {
        this.toast.sToast('error', result[1]);
      }
    });
  }

  performanceManagement(): void {
    // @ts-ignore
    this.loaderService.display(true, `Updating agent performance count`);
    this.baseService
      .doRequest(
        `/api/agent/imaws/change_agentperformancecount`,
        'post',
        this.probeScanData
      ).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result[0]) {
        this.toast.sToast('success', 'Sucessfully updated.');
        this.modalService.close('updatePerformanceManagement');
        this.getAgents();
      } else {
        this.toast.sToast('error', result[1]);
      }
    });
  }

  getCompanies(search?: string): void {
    if (!this.aS || !this.aS.isAuthenticated) {
      setTimeout(() => {
        this.getCompanies();
      }, 2000);
      return;
    }
    let cq: any;
    const cmpq = {
      query: {
        bool: {
          must: [{ exists: { field: 'description' } }, {terms: {'_type_.keyword': ['company', 'companycreate']}}],
          must_not: [
            { match: { isAssessment: true } },
          ],
        },
      },
    };
    const asmq = {
      query: {
        bool: {
          must: [
            { match: { isAssessment: true } },
            { exists: { field: 'description' } },
            {terms: {'_type_.keyword': ['company', 'companycreate']}}
          ],
        },
      },
    };
    cq = this.baseService.showAssessment ? asmq : cmpq;
    if (search && search !== '') {
      // @ts-ignore
      cq.query.bool.must.push({
        match_bool_prefix: { name: search.toLowerCase() },
      });
    }
    const q = JSON.stringify(cq);
    const skip = 0;
    const limit = 1000;
    const sort = JSON.stringify([{ 'name.keyword': { order: 'asc' } }]);
    const fields = JSON.stringify([
      'name',
      'customerInfo',
      'c',
      'isAssessment',
    ]);
    this.searching = true;
    this.companyService
      .getAllApiCompanyGet({ q, skip, limit, sort, fields })
      .subscribe(
        (result: any) => {
          this.searching = false;
          if (result.data.length) {
            result.data.sort((a: any, b: any) => {
              const c = a.name ? a.name.toLowerCase() : '';
              const d = b.name ? b.name.toLowerCase() : '';
              if (c < d) {
                return -1;
              } else if (c > d) {
                return 1;
              } else {
                return 0;
              }
            });
            result.data = result.data.filter(
              (c: any) => c._id !== this.currentCompany?._id
            );
            if (!search) {
              this.allComp = result.data;
            }
            this.filteredCompanies.next(result.data.slice());
          } else {
            this.filteredCompanies.next(result.data.slice());
          }
        },
        (error) => {
          // no errors in our simulated example
          this.searching = false;
          // handle error...
        }
      );
  }
  allActioncall(idata: any): void {
    const dataRow = idata.row;
    if (idata.action.text === 'Discovery Settings') {
      if (dataRow.agent_type != 'Probe') {
        this.toast.sToast(
          'error',
          'Discovery Settings is not supported for Lightweight Agent.'
        );
        return;
      }
      this.currentAgent = dataRow;
      this.agentId = dataRow._id;
      this.agentsView = 'Discovery Settings';
    } else if (idata.action.text === 'Scan') {
    } else if (idata.action.text === 'Uninstall') {
      if (dataRow.agent_type === 'External Scan Agent') {
        this.toast.sToast(
          'error',
          'External scan agent uninstall not allowed.'
        );
      } else {
        this.uninstallAgent(dataRow);
      }
    } else if (idata.action.text === 'Delete') {
      if (dataRow.agent_type === 'External Scan Agent') {
        this.toast.sToast('error', 'External scan agent delete not allowed.');
      } else {
        this.deleteAgent(dataRow);
      }
    } else if (idata.action.text === 'Migrate to LightWeightAgent') {
      if(!dataRow.agentStatus){
        this.toast.sToast('error', 'Please select only online agents.');
        return;
      }
      const agent_ids = [dataRow._id];
      this.migrateAgent(agent_ids, 3, 1);
    } else if (idata.action.text === 'Migrate to Probe') {
      if(!dataRow.agentStatus){
        this.toast.sToast('error', 'Please select only online agents.');
        return;
      }
      const agent_ids = [dataRow._id];
      this.migrateAgent(agent_ids, 1, 3);
    } else if (idata.action.text === 'Fetch Event Logs') {
      if (this.isDeprecated) {
        this.toast.sToast(
          'error',
          'Unable to fetch event logs as Agent(s) is Deprecated.'
        );
        return;
      }
      if (dataRow.ostype !== 'Windows' || !dataRow.agentStatus) {
        this.toast.sToast(
          'error',
          'Fetching Event logs is applicable only for Online Windows agent.'
        );
        return;
      }
      this.cs.agentDeprecateEVE.next({
        isDeprecated: this.isDeprecated,
        agentIds: [idata.row],
        id: 'eventAgentLogs',
        currentEventLogs: {
          name: idata.row.name,
          agentId: idata.row._id,
          startDate: null,
          endDate: null,
        }
      });
    } else if (idata.action.text === 'Agent Update Info') {
      this.cs.agentDeprecateEVE.next({
        isDeprecated: this.isDeprecated,
        agentIds: [idata.row],
        id: 'agentLogs',
        agentLogData: {name: idata.row.name, _id: idata.row._id}
      });
    } else if (
      idata.action.text === 'Deprecate' ||
      idata.action.text === 'Restore'
    ) {
      this.currentAgent = idata.row;
      this.cs.agentDeprecateEVE.next({
        isDeprecated: this.isDeprecated,
        agentIds: [idata.row],
        id: 'singleagentDeprecate',
      });
    }
  }

  

  

  closeCurrentCompany(event: any): void {
    this.companiesList = Object.assign([], this.allComp);
    this.filteredCompanies.next(this.allComp.slice());
  }

  globalActionCall(data: any): void {
    if (data.action.text === 'Delete') {
      const externalScan = data.row.filter(
        (i: any) => i.agent_type === 'External Scan Agent'
      );
      if (externalScan && externalScan.length) {
        this.toast.sToast('error', 'External scan agent delete not allowed.');
      } else {
        this.deleteMultiAgent(data);
      }
    }
    if (data.action.text === 'Uninstall') {
      const externalScan = data.row.filter(
        (i: any) => i.agent_type === 'External Scan Agent'
      );
      if (externalScan && externalScan.length) {
        this.toast.sToast(
          'error',
          'External scan agent uninstall not allowed.'
        );
      } else {
        this.uninstallMultiAgent(data);
      }
    }
    if (data.action.text === 'LightWeight Agent Scan') {
      this.probeScanData.agent_ids = data.row.filter( (i: any) => i.agentStatus).map((x: any) => x._id);
      if(this.probeScanData.agent_ids.length === 0){
        this.toast.sToast('error', 'Please select only online Lightweight agents.');
        return;
      }
      this.probeScanData.scantype = '';
      this.probeScanData.companyid = this.currentCompany?._id
      this.modalService.open('lightWeightScan');
    }

    if (data.action.text === 'Probe Agent Scan') {
      this.probeScanData.agent_ids = data.row.filter( (i: any) => i.agentStatus ).map((x: any) => x._id);
      if(this.probeScanData.agent_ids.length === 0){
        this.toast.sToast('error', 'Please select only online Probe agents.');
        return;
      }
      this.probeScanData.scantype = '';
      this.probeScanData.companyid = this.currentCompany?._id
      this.modalService.open('probeScan');
    }
    if (data.action.text === 'Update Performance Management Count') {
      this.probeScanData.agent_ids = data.row.map((x: any) => x._id);
      this.probeScanData.maxThreadCount  = 0;
      this.modalService.open('updatePerformanceManagement');
    }
    if (data.action.text === 'Migrate to Probe') {
      const agent_ids = data.row.filter( (i: any) => i.agentStatus ).map((x: any) => x._id);
      if(agent_ids.length === 0){
        this.toast.sToast('error', 'Please select only online agents.');
        return;
      }
      this.migrateAgent(agent_ids, 1, 3);
    }
    if (data.action.text === 'Migrate to LightWeightAgent') {
      const agent_ids = data.row.filter( (i: any) => i.agentStatus ).map((x: any) => x._id);
      if(agent_ids.length === 0){
        this.toast.sToast('error', 'Please select only online agents.');
        return;
      }
      this.migrateAgent(agent_ids, 3, 1);
    }
    if (data.action.text === 'Agent Migration') {
      this.getCompanies();
      this.migrationData = {
        source_companyid: this.currentCompany?._id,
        destination_companyid: '',
      };
      this.migrationData.agentids = data.row.map((i: any) => i._id);
      this.migrate.open();
    }
    if (data.action.text === 'Deprecate' || data.action.text === 'Restore') {
      this.allAgent = data.row;
      this.cs.agentDeprecateEVE.next({
        isDeprecated: this.isDeprecated,
        agentIds: data.row,
        id: 'agentDeprecate',
      });
    }
  }

  destinationCompanySelecte(event: any): void {
    const destinationCompany = this.allComp.filter(
      (c: any) => c._id === event.value
    );
    this.migrationData.destinationCompany =
      destinationCompany && destinationCompany.length
        ? destinationCompany[0].name
        : '';
  }

  agentMigration(): void {
    const titleName = 'Confirmation';
    const message = `Agents will be migrated from from <b>${this.currentCompany?.name}</b> to <b>${this.migrationData?.destinationCompany}</b>. <br>
      Please note: Only the <b>Online</b>
      agents will be migrated now,<br>you will have to initiate migration for Offline agents once they are back Online.`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    const htmlMsg = true;
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText,
      '',
      '',
      htmlMsg
    );
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(true);
        this.baseService
          .doRequest(
            `/api/company/cybercns/migrateAgents`,
            'post',
            this.migrationData
          )
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result[0]) {
              this.toast.sToast('success', 'Agents migration initiated!');
              setTimeout(() => {
                this.migrationData = {
                  source_companyid: '',
                  destination_companyid: '',
                };
                this.migrate.close();
                this.getAgents();
              }, 2000);
            } else {
              this.toast.sToast('error', result[1]);
            }
          });
      }
    });
  }

  lightweightAgentScan(agent: any): void {
    const titleName = 'Confirmation';
    const message =
      'Are you sure you want to scan the selected agent entries ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        const selectedId = agent.row.map((s: any) => s._id);
        this.baseService
          .doRequest(`/api/agent/iamaws/lightweightscan`, 'post', {
            agent_ids: selectedId,
          })
          .subscribe((result: any) => {
            if (result[0]) {
              this.toast.sToast('success', 'Scan initiated...');
              setTimeout(() => {
                this.getAgents();
              }, 3000);
            } else {
              this.toast.sToast('error', result[1]);
            }
          });
      }
    });
  }

  uninstallMultiAgent(agent: any): void {
    const titleName = 'Confirmation';
    const message =
      'Are you sure you want to uninstall the selected agent entries ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        const selectedId = agent.row.map((s: any) => s._id);
        this.baseService
          .doRequest(`/api/agent/iamaws/uninstallAgent`, 'post', {
            agent_id_bulk: selectedId,
            agent_id: '',
          })
          .subscribe((result: any) => {
            if (result) {
              this.toast.sToast('success', 'Uninstalling initiated...');
              setTimeout(() => {
                this.getAgents();
              }, 3000);
            } else {
              this.toast.sToast('error', result.msg);
            }
          });
      }
    });
  }

  deleteMultiAgent(agent: any): void {
    const titleName = 'Confirmation';
    const message =
      'This will delete all the data associated with the selected agent. Are you sure you want to delete?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        const selectedId = agent.row.map((s: any) => s._id);
        this.baseService
          .doRequest(`/api/agent/imaws/agentsbulkdelete`, 'post', {
            agent_ids: selectedId,
          })
          .subscribe((result: any) => {
            if (result) {
              this.toast.sToast('success', 'Delete initiated...');
              setTimeout(() => {
                this.getAgents();
              }, 3000);
            } else {
              this.toast.sToast('error', result);
            }
          });
      }
    });
  }

  deleteAgent(agent: any): void {
    const titleName = 'Confirmation';
    const message =
      'This will delete all the data associated with the agent ' +
      agent.host_name +
      '. Are you sure you want to delete?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        this.baseService
          .doRequest(`/api/agent/${agent._id}`, 'delete')
          .subscribe((result: any) => {
            if (result) {
              this.toast.sToast('success', 'Delete initiated...');
              setTimeout(() => {
                this.getAgents();
              }, 3000);
            } else {
              this.toast.sToast('error', result);
            }
          });
      }
    });
  }

  migrateAgent(agent_ids: any, change_to: any, change_from: any): void {
    const titleName = 'Warning!!!';
    let message = 'Are you sure you want to migrate?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    message = (change_to === 3) ? `<p class="t-d">Proceed with caution when initiating the migration process.</p>
        <p>If you choose to migrate from Probe to Lightweight:</p>
        <ol class="t-d"><li>All assets discovered by the probe will be deleted, except for the probe itself.</li>
<li>Discovery settings added to the probe will be deleted.</li>
<li>All credentials associated with the probe will be deleted.</li>
<li>Any job running on the probe machine will be terminated.</li>
<li>All pending jobs in the queue will be deleted.</li></ol>
<p>Are you certain about proceeding with the migration?</p>` : `<p class="t-d">Proceed with caution when initiating the migration process.</p>
        <p>If you choose to migrate from Lightweight to Probe:</p>
        <ol class="t-d"><li>The agent type of assets will be updated.</li>
<li>The agent associated with the asset will be updated accordingly.</li></ol>
<p>Are you certain about proceeding with the migration?</p>`;
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText, undefined, undefined, true,
    );
    this.confirmDialog.dialogResult.subscribe((res) => {
      if (res) {
        const data = {agent_ids, change_to, change_from}
        this.baseService
          .doRequest(`/api/agent/imaws/change_agenttype`, 'post', data)
          .subscribe((result: any) => {
            if (result[0]) {
              this.toast.sToast('success', result[1]);
              setTimeout(() => {
                this.getAgents();
              }, 3000);
            } else {
              this.toast.sToast('error', result[1]);
            }
          });
      }
    });
  }

  uninstallAgent(agent: any): void {
    const titleName = 'Confirmation';
    const message =
      'Are you sure you want to uninstall this agent ' + agent.host_name + ' ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(
      titleName,
      message,
      cancelText,
      acceptText
    );
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.baseService
          .doRequest(`/api/agent/${agent._id}/uninstallAgent`, 'post', {
            agent_id: `${agent._id}`,
          })
          .subscribe((result: any) => {
            if (result) {
              this.toast.sToast('success', 'Uninstalling initiated...');
              setTimeout(() => {
                this.getAgents();
              }, 3000);
            } else {
              this.toast.sToast('error', result.msg);
            }
          });
      }
    });
  }

  agenshowHideLoading(status: boolean): void {
    const data = Object.assign({}, this.agenTableOptions);
    this.agenTableOptions = {};
    this.agenTableOptions = data;
    this.agenTableOptions.tableOptions.loading = status;
  }

  /*Agent Table Fn*/
  colFilterCall(event: any): void {
    this.colFilterQuery = [];
    if (event.col === 'host_name') {
      this.hName = event.value === '' ? undefined : event.value;
    }
    if (event.col === 'version') {
      this.aVersion = event.value === '' ? undefined : event.value;
    }
    if (event.col === 'ip') {
      this.aIP = event.value === '' ? undefined : event.value;
    }
    if (event.col === 'agent_status') {
      this.agentStatus = event.value === '' ? undefined : event.value;
    }
    if (event.col === 'ostype') {
      this.externalOS = event.value === '' ? undefined : event.value;
    }
    if (event.col === 'agent_type') {
      this.externalAType = event.value === '' ? undefined : event.value;
    }
    if (event.col === 'companyRef.name') {
      this.cName = event.value === '' ? undefined : event.value;
    }
    console.log(this.colFilterCols);
    if (!this.colFilterCols.filter((x: any) => x.col === event.col).length) {
      if (event.value !== '') {
        this.colFilterCols.push(event);
      }
    } else {
      this.colFilterCols.forEach((obj: any, index: number) => {
        if (obj.col === event.col && event.value === '') {
          this.colFilterCols.splice(index, 1);
        } else if (obj.col === event.col) {
          obj.value = event.value;
        }
      });
    }
    this.colFilterCols.forEach((obj: any) => {
      const specialChars = /[`!@#$%^&*() _+\-=\[\]{};':"\\|,.<>\/?~]/;
      const searchValue =
        typeof obj.value === 'string' ? obj.value.trim() : obj.value;
      const qval =
        specialChars.test(searchValue) &&
        obj.col !== 'ip' &&
        obj.col !== 'version'
          ? `\"${searchValue}\"`
          : '*' + searchValue + '*';
      const tmpObj = {
        bool: {
          should: [{ query_string: { fields: [obj.col], query: `${qval}` } }],
          minimum_should_match: 1,
        },
      };
      if (obj.col === 'agent_type') {
        this.externalAType = searchValue
          ? this.agentEnum[searchValue]
          : undefined;
        this.colFilterQuery.push({
          bool: { should: [{ match: { agent_type: searchValue } }] },
        });
      } else if (obj.col === 'ostype') {
        this.externalOS = searchValue ? this.enumData[searchValue] : undefined;
        this.colFilterQuery.push({
          bool: { should: [{ match: { ostype: searchValue } }] },
        });
      } else if (obj.col === 'agent_status') {
        this.agentStatus = searchValue === '' ? undefined : searchValue;
      } else {
        this.colFilterQuery.push(tmpObj);
      }
    });
    if (this.colFilterQuery.length) {
      this.agentsCurrentPage = 0;
    }
    this.getAgents();
  }

  sortCall(event: any): void {
    this.agenTableOptions.sortOptions = event;
    this.getAgents();
  }

  filterCall(event: any): void {
    const fields = [];
    this.agenTableOptions.columns.forEach((obj: any) => {
      fields.push(obj.columnDef);
    });
    const searchAsset = event;
    let qval = event.replace(
      /([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:.\ \\/"])/g,
      '\\$1'
    );
    if (qval.indexOf('\\') === -1) {
      qval = '*' + qval + '*';
    }
    this.filterQuery =
      event && event.length > 0
        ? {
            query_string: {
              query: qval,
              fields: ['host_name', 'name', 'version', 'ip', 'companyRef.name'],
            },
          }
        : undefined;
    this.getAgents(searchAsset);
  }

  closeDiscoverySettings(): void {
    this.agentsView = 'agents'; this.getAgents();
  }

  getAgents(search?: any): void {
    this.agenshowHideLoading(true);
    this.loaderService.display(true, 'Getting Agents...');
    this.agenTableOptions.serverSide = {
      service: 'agentService',
      fn: 'getAllApiAgentGet',
      q: {
        query: {
          bool: {
            must: [{ exists: { field: 'agent_type' } }, {match:{ agent_type: `${this.selectedAgent}` + '' }},],
          },
        },
      },
    };
    let query: any;
    query = {
      query: {
        bool: {
          must: [{ exists: { field: 'agent_type' } }],
        },
      },
    };

    !this.isDeprecated
      ? this.agenTableOptions.serverSide.q.query.bool.must.push({
          match: { agent_type: `${this.selectedAgent}` + '' },
        })
      : null;
    !this.isDeprecated
      ? query.query.bool.must.push({
          match: { agent_type: `${this.selectedAgent}` + '' },
        })
      : null;
    if (this.currentCompany && this.currentCompany._id) {
      this.agenTableOptions.tableOptions.actionMenuItems = [
        {
          text: 'Uninstall',
          icon: 'remove_circle_outline',
          callback: 'detailFn',
          isGlobal: true,
        },
        {
          text: 'Delete',
          icon: 'delete_forever',
          callback: 'detailFn',
          isGlobal: true,
        },
        {
          text: 'Agent Migration',
          icon: 'find_replace',
          callback: 'migrationFn',
          isGlobal: true,
          hideLocal: true,
        },
        {
          text: 'Fetch Event Logs',
          icon: 'find_in_page',
          callback: 'downloadFn',
          isGlobal: false,
        },
        {
          text: 'Agent Update Info',
          icon: 'change_circle',
          callback: 'agentFn',
          isGlobal: false,
        },
      ];
      if( this.selectedAgent === 1){
        this.agenTableOptions.tableOptions.actionMenuItems.unshift({
          text: 'Discovery Settings',
          icon: 'search',
          callback: 'detailFn',
          isGlobal: false,
        })
        this.agenTableOptions.tableOptions.actionMenuItems.push({
          text: 'Probe Agent Scan',
          icon: 'scanner',
          callback: 'scanFn',
          isGlobal: true,
          hideLocal: true,
        })
        this.agenTableOptions.tableOptions.actionMenuItems.push({
          text: 'Update Performance Management Count',
          icon: 'update',
          callback: 'updateFn',
          isGlobal: true,
          hideLocal: true,
        })
        if (this.aS.hasPermission('agent', 'change_agenttype')) {
          this.agenTableOptions.tableOptions.actionMenuItems.push({
            text: 'Migrate to LightWeightAgent',
            icon: 'find_replace',
            callback: 'migrateToProbe',
            isGlobal: true,
            hideLocal: false,
          })
        }
      }else {
        this.agenTableOptions.tableOptions.actionMenuItems.push({
          text: 'LightWeight Agent Scan',
          icon: 'scanner',
          callback: 'scanFn',
          isGlobal: true,
          hideLocal: true,
        })
        if (this.aS.hasPermission('agent', 'change_agenttype')) {
          this.agenTableOptions.tableOptions.actionMenuItems.push({
            text: 'Migrate to Probe',
            icon: 'find_replace',
            callback: 'migrateToProbe',
            isGlobal: true,
            hideLocal: false,
          })
        }
      }
      this.agenTableOptions.serverSide.q.query.bool.must.push({
        match: { 'companyRef.id.keyword': `${this.currentCompany._id}` + '' },
      });
      query.query.bool.must.push({
        match: { 'companyRef.id.keyword': `${this.currentCompany._id}` + '' },
      });
      this.agenTableOptions.serverSide.q.query.bool.must_not = [
        { match: { agent_type: 2 } },
      ];
      query.query.bool.must_not = [{ match: { agent_type: 2 } }];
    } else {
      if( this.selectedAgent === 1){
        this.agenTableOptions.tableOptions.actionMenuItems.push({
          text: 'Update Performance Management Count',
          icon: 'update',
          callback: 'updateFn',
          isGlobal: true,
          hideLocal: true,
        })
      }
      const isCmp = this.agenTableOptions.columns.filter(
        (x: any) => x.header === 'Company Name'
      );
      if (!isCmp || !isCmp.length) {
        this.agenTableOptions.columns.push({
          header: 'Company Name',
          id: 'companyRefname',
          columnDef: 'companyRef.name',
          filter: '',
          cell: '(element: any) => `${element.companyRef.name}`',
          order: 2,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
        });
        this.agenTableOptions.columns = this.agenTableOptions.columns.filter(
          (list: any) => list.header !== 'Agent Type'
        );
      }
    }
    this.agenTableOptions.tableOptions.title =  this.selectedAgent === 1 && !this.isDeprecated
      ? 'Probe Agents'
      : this.selectedAgent === 3 && !this.isDeprecated
      ? 'LightWeight Agents'
      : 'Deprecated Agents'

    const isCmp = this.agenTableOptions.tableOptions.actionMenuItems.filter(
      (x: any) => x.callback === 'deprecateFn'
    );
    if (!isCmp || !isCmp.length) {
      this.agenTableOptions.tableOptions.actionMenuItems.push({
        text: this.isDeprecated ? 'Restore' : 'Deprecate',
        icon: 'repeat',
        callback: 'deprecateFn',
        isGlobal: true,
      });
    }
    if (this.isDeprecated) {
      this.agenTableOptions.serverSide.q.query.bool.must.push({
        match: { isdeprecated: true },
      });
      query.query.bool.must.push({ match: { isdeprecated: true } });
      this.agenTableOptions.tableOptions.actionMenuItems = [
        {
          text: this.isDeprecated ? 'Restore' : 'Deprecate',
          icon: 'repeat',
          callback: 'deprecateFn',
          isGlobal: true,
        },
        {
          text: 'Delete',
          icon: 'delete_forever',
          callback: 'detailFn',
          isGlobal: true,
        },
      ];
    } else {
      if (
        !query.query.bool.must_not &&
        !this.agenTableOptions.serverSide.q.query.bool.must_not
      ) {
        this.agenTableOptions.serverSide.q.query.bool.must_not = [];
        query.query.bool.must_not = [];
      }
      this.agenTableOptions.serverSide.q.query.bool.must_not.push({
        match: { isdeprecated: true },
      });
      query.query.bool.must_not.push({ match: { isdeprecated: true } });
    }
    if (
      this.filterQuery &&
      (this.filterQuery.multi_match || this.filterQuery.query_string)
    ) {
      query.query.bool.must.push(this.filterQuery);
    }
    if (this.colFilterQuery && this.colFilterQuery.length) {
      query.query.bool.filter = [];
      this.colFilterQuery.forEach((obj: any) => {
        if (obj.bool.should[0].match) {
          query.query.bool.must.push(obj);
        } else {
          query.query.bool.filter.push(obj);
        }
      });
    }
    this.agenTableOptions.tableOptions.colFilterQuery = this.colFilterQuery;
    const q = JSON.stringify(query);
    let skip = this.agentsCurrentPage;
    this.agenTableOptions.tableOptions.skip = this.agentsCurrentPage;
    let limit = this.agenTableOptions.tableOptions.pageSize;
    if ( this.agenTableOptions.sortOptions &&
      ['ip', 'agentStatus'].indexOf(this.agenTableOptions.sortOptions.active) !== -1 ) {
      limit = 5000;
      skip = 0;
    }
    if(search){ limit = 1000; skip = 0;};
    this.agenTableOptions.pageData = [];
    this.agenTableOptions.tableOptions.pageTotal = 0;
    let sort: any = [{}];
    if (
      this.agenTableOptions.sortOptions &&
      this.agenTableOptions.sortOptions.direction &&
      this.agenTableOptions.sortOptions.direction !== ''
    ) {
      const orderArr = [
        '_id',
        'host_name',
        'name',
        'version',
        'ip',
        'companyRef.name',
      ];
      if (orderArr.indexOf(this.agenTableOptions.sortOptions.active) > -1) {
        sort[0][this.agenTableOptions.sortOptions.active + '.keyword'] = {
          order: this.agenTableOptions.sortOptions.direction,
        };
      } else {
        sort[0][this.agenTableOptions.sortOptions.active] = {
          order: this.agenTableOptions.sortOptions.direction,
        };
      }
    }
    sort = JSON.stringify(sort);
    const fields = JSON.stringify([
      'c',
      'u',
      '_id',
      'host_name',
      'name',
      'version',
      'ip',
      'maxThreadCount',
      'agent_type',
      'ostype',
      'companyRef.name',
      'companyRef.id',
      'lastscannedtime',
      'agentStatus',
      'lastConnectedTime',
      'bruteForcePorts',
      'bruteForceEnabled',
      'lastpiiscan_time'
    ]);
    this.agentService
      .getAllApiAgentGet({ q, skip, limit, sort, fields })
      .subscribe((result: any) => {
        this.loaderService.display(false);
        if (result && result.data.length) {
          if (
            this.agenTableOptions.sortOptions &&
            ['ip', 'agentStatus'].indexOf(this.agenTableOptions.sortOptions.active) !== -1
          ) {
            result.data = this.commonService.sortIP(
              result.data,
              this.agenTableOptions.sortOptions.active,
              this.agenTableOptions.sortOptions.direction,
              this.agentsCurrentPage *
                this.agenTableOptions.tableOptions.pageSize,
              this.agenTableOptions.tableOptions.pageSize
            );
          }
          if (this.isDeprecated) {
            this.cs.agentCheckItem.next(false);
          } else {
            this.cs.agentCheckItem.next(true);
          }
          this.totalProbe = result.data.length;
          result.data.forEach((obj: any, index: number) => {
            obj.ostype = this.enumData[obj.ostype];
            obj.agent_type = this.agentEnum[obj.agent_type];
            if (!obj.companyRef || !obj.companyRef.id) {
              obj.companyRef = { id: '', name: '-' };
            }
          });
          const responseData = (search) ? this.searchPipe.transform(result.data, search) : result.data;
          this.agenTableOptions.pageData = responseData;
          this.agenTableOptions.tableOptions.pageTotal = (search) ? responseData.length : result.total;
          this.agenshowHideLoading(false);
        } else {
          this.cs.agentCheckItem.next(false);
          this.agenTableOptions.pageData = [];
          this.agenTableOptions.tableOptions.pageTotal = 0;
          this.agenshowHideLoading(false);
        }
      });
  }

  addAgent(): void {
    this.agent = {
      name: '',
      agent_type: 1,
      agentcompanyRef: { id: '', name: '' },
    };
    this.modalService.open('addAgent');
  }

  saveAgent(): void {
    this.isLoading = true;
    this.loaderService.Modeldisplay(true);
    this.agent.agentcompanyRef = {
      id: this.currentCompany._id,
      name: this.currentCompany.name,
    };
    this.agentService
      .createApiAgentPost({ body: this.agent })
      .subscribe((result: Agent) => {
        this.loaderService.Modeldisplay(false);
        this.isLoading = true;
        if (result._id) {
          this.modalService.close('addAgent');
          this.toast.sToast('success', 'Agent added successfully!');
          this.getAgents();
        } else {
          this.toast.sToast('error', JSON.stringify(result));
        }
      });
  }

  agentsPagecallback(event: any): void {
    this.agenTableOptions.tableOptions.pageSize = event.pageSize;
    this.agentsCurrentPage = event.pageIndex;
    this.getAgents();
  }

  getExternalScan(): void {
    this.loaderService.display(true, 'Getting external scan agent');
    this.commonService.getAgents().then((value: any) => {
      this.agenshowHideLoading(true);
      if (value.data.length) {
        value.data[0].agent_type = this.agentEnum[value.data[0].agent_type];
        value.data[0].ostype = this.enumData[value.data[0].ostype];
        value.data[0].agent_status = true;
        value.data[0].companyRef = { id: '', name: '-' };
        if (
          this.agenTableOptions.pageData.filter(
            (x: any) => x.agent_status === 'External Scan Agent'
          ).length
        ) {
          return;
        } else {
          let values: any;
          if (this.aIP) {
            values = value.data.filter((x: any) => x.ip.indexOf(this.aIP) > -1);
          }
          if (this.hName) {
            values = value.data.filter(
              (x: any) => x.host_name.indexOf(this.hName) > -1
            );
          }
          if (this.aVersion) {
            values = value.data.filter(
              (x: any) => x.version.indexOf(this.aVersion) > -1
            );
          }
          if (this.externalOS) {
            values = value.data.filter(
              (x: any) => x.ostype === this.externalOS
            );
          }
          if (this.externalAType) {
            values = value.data.filter(
              (x: any) => x.agent_type === this.externalAType
            );
          }
          if (this.agentStatus === false && this.colFilterCols.length) {
            values = [];
          }
          if (this.filterQuery) {
            values = [];
          }
          if (!values) {
            this.agenTableOptions.pageData.unshift(value.data[0]);
            this.agenTableOptions.tableOptions.pageTotal += 1;
          } else if (values.length) {
            this.agenTableOptions.pageData.unshift(values[0]);
            this.agenTableOptions.tableOptions.pageTotal += 1;
          }
          this.agenTableOptions.pageData =
            this.agenTableOptions.pageData.filter(
              (thing: any, index: any, self: any) =>
                index ===
                self.findIndex(
                  (t: any) =>
                    t.ip === thing.ip &&
                    t.agent_status === thing.agent_status &&
                    t.c === thing.c &&
                    t.u === thing.u
                )
            );
          this.agenTableOptions.tableOptions.pageTotal =
            this.agenTableOptions.pageData.length;
        }
      }
      this.agenshowHideLoading(false);
      this.loaderService.display(false);
    });
  }
}
