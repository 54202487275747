import {Component, Input, OnInit} from '@angular/core';
import { CompanySharedService } from 'src/app/_services/company-shared.service';

@Component({
  selector: 'app-evidence',
  templateUrl: './evidence.component.html',
  styleUrls: ['./evidence.component.scss']
})

export class EvidenceComponent implements OnInit {
  @Input() solution: any;
  @Input() prodHash: any;
  @Input() statusFilterKey: any;
  assets: any = [];
  loading = true;
  constructor( public cS: CompanySharedService,) {
    cS.evidenceSortEVE.subscribe((value: any) => {
      this.assets = [];
      this.assets = this.sortData(value.key, (value.sort === 'asc') ? true : false)
    });
  }

  isObject(obj: any): boolean {
    return (typeof obj === 'object');
  }

  compare(a: number | string, b: number | string, isAsc: boolean): any {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  sortData(key: any, isAsc: any): any {
    return this.solution.assets.sort((a: any, b: any) => {
      return (key === 'name') ? this.compare(a[key].toLowerCase(), b[key].toLowerCase(), isAsc) : this.compare(a[key], b[key], isAsc);
    })
  }
  ngOnInit(): void {
    console.log("statusFilterKey", this.statusFilterKey);
    this.assets = this.sortData('name', true);
    setTimeout(() => { this.loading = false; }, 500);
  }
}
