import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { JobsService } from 'src/app/api/services';
import { BaseRequestService } from 'src/app/_services/base.service';
import { CommonService } from 'src/app/_services/common.services';
import { CompanySharedService } from 'src/app/_services/company-shared.service';
import { ConfirmDialogService } from 'src/app/_services/confirmdialog.service';
import { LoaderService } from 'src/app/_services/loader.service';
import { ModalService } from 'src/app/_services/modal.service';
import { MyToastrService } from 'src/app/_services/toastr.service';

@Component({
  selector: 'app-report-jobs',
  templateUrl: './report-jobs.component.html',
  styleUrls: ['./report-jobs.component.scss'],
})
export class ReportJobsComponent implements OnInit {
  @ViewChild('reportsnav', { static: true }) reportsnav: MatSidenav;
  @Input() currentCompany: any;
  reportJobTableOptions: any;
  currentJob: any;
  filterQuery: any;
  reportJobcurrentPage = 0;
  colFilterQuery: any;
  colFilterCols: any = [];
  showUpper = true;
  reportJobStatusCols: any = ['report_name', 'report_type', 'status', 'reason'];
  reportJobStatusCustomCols: any = [
    {
      col: 'status', htmlCols: {
        _success: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleCheck text-success"></i>`,
        _failure: `<i class=" ms-Icon ms-font-lg pl-3 ms-Icon--SkypeCircleMinus text-danger"></i>`
      }
    },
    {col: 'reason', filter: 'ellipsis:25'}
  ];
  constructor(
    private loaderService: LoaderService,
    private toast: MyToastrService,
    private confirmDialog: ConfirmDialogService,
    private comS: CommonService,
    private cs: CompanySharedService,
    public baseService: BaseRequestService,
    public modalService: ModalService,
    private jobsService: JobsService
  ) {
    this.reportJobTableOptions = {
      columns: [
        {
          header: 'Created',
          columnDef: 'c',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.c}`',
          order: 1,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: true,
          dateFilter: true,
          filterData: { start: '', end: '' }
        },
        {
          header: 'Report Name',
          columnDef: 'job_data.report_name',
          filter: '',
          cell: '(element: any) => `${element.job_data.report_name}`',
          order: 9,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: false,
          iscolumnSearch: true,
        },
        {
          header: 'Report Type',
          columnDef: 'job_data.report_type',
          filter: '',
          cell: '(element: any) => `${element.job_data.report_type}`',
          order: 9,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: false,
          iscolumnSearch: true,
        },
        {
          header: 'Job Status',
          columnDef: 'job_data.job_status',
          filter: '',
          cell: '(element: any) => `${element.job_data.job_status}`',
          order: 4,
          img: true,
          conditionList: [
            {
              _img: '/assets/images/loading.gif',
              value: 1,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/loading.gif',
              value: 2,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/loading.gif',
              value: 3,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/tick.svg',
              value: 5,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/cross.svg',
              value: 6,
              class: 'imageWidth',
            },
            {
              _img: '/assets/images/auditLogins/alert.svg',
              value: 4,
              class: 'imageWidth',
            },
          ],
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          selectFilter: true,
          addingText: '',
          imgPath: '',
          isSort: true,
          iscolumnSearch: true,
          selectFilterArr: [
            { name: 'Running', value: 3 },
            { name: 'Success', value: 5 },
            { name: 'Partial', value: 4 },
            { name: 'Failed', value: 6 },
          ],
        },
        {
          header: 'JOB ID',
          columnDef: '_id',
          filter: '',
          cell: '(element: any) => `${element._id}`',
          order: 5,
          visible: false,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          imgPath: '',
          isSort: true,
          iscolumnSearch: false,
        },
        {
          header: 'Status',
          columnDef: 'job_data.status_message',
          filter: '',
          cell: '(element: any) => `${element.job_data.status_message}`',
          order: 8,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: true,
        },
        {
          header: 'When',
          columnDef: 'u',
          filter: 'utcToLocale',
          cell: '(element: any) => `${element.u}`',
          order: 8,
          visible: true,
          isToolTip: false,
          isToolTipCol: '',
          hasMultiData: false,
          class: '',
          color: '',
          isProgressCntrl: false,
          isColoredCntrl: false,
          colList: [],
          isfaicon: false,
          isAddingText: false,
          addingText: '',
          img: false,
          isSort: true,
          iscolumnSearch: true,
          dateFilter: true,
          filterData: { start: '', end: '' }
        },
      ],
      title: 'Report Jobs',
      cFilter: true,
      faClass: 'Jobs',
      sortOptions: { active: 'u', direction: 'desc' },
      _pageData: [],
      tableOptions: {
        id: 'reportJobTableOptions',
        title: 'Report Jobs',
        isServerSide: true,
        selectText: 'job(s)',
        loading: true,
        floatingFilter: true,
        rowSelection: true,
        showAction: true,
        actionMenuItems: [
          {
            text: 'Details',
            icon: 'info',
            callback: 'detailFn',
            isGlobal: false,
          },
          {
            text: 'Download Report',
            icon: 'cloud_download',
            callback: 'downloadFn',
            isGlobal: false,
          },
          {text: 'Terminate', icon: 'stop_circle', callback: 'detailFn', isGlobal: true},
          {
            text: 'Retry',
            icon: 'refresh',
            callback: 'retryFn',
            isGlobal: false,
          },
        ],
        pagination: true,
        pageOptions: [5, 10, 25, 50, 100],
        pageSize: 10,
        selectedTimer: "60",
        search: true,
        showhideList: true,
        refreshData: true,
        exportExcel: false,
        add: false,
        saveData: false,
        columnSearch: false,
        compareData: false,
      },
    };
  }

  ngOnInit(): void {
    this.reportJobTableOptions.pageData = [];
    this.getReportJobs();
  }

  close(status?: any): any {
    if (status) {
      return;
    }
    this.reportsnav.close();
  }

  reportJobstimerCallData(event: any): void {
    this.getReportJobs();
  }

  getReportJobs(): void {
    const query: any = {
      query: {
        bool: {
          must: [{exists: {field: 'job_data.report_name'}}, {exists: {field: 'job_data.report_job_message'}}],
          must_not: [{ match: { 'job_data.task.keyword': `Report Scheduler` } }]
        }
      }
    };
    if (this.currentCompany && this.currentCompany._id) {
      query.query.bool.must.push({match: {'companyRef.id.keyword': `${this.currentCompany._id}`}});
    } else {
      query.query.bool.must_not = [{exists: {field: 'companyRef'}}];
    }
    this.reportJobTableOptions.serverSide = {
      service: 'jobsService', fn: 'getAllApiJobsGet', q: query
    };
    if (this.filterQuery && this.filterQuery.multi_match) {
      query.query.bool.must.push(this.filterQuery);
    }
    if (this.filterQuery && this.filterQuery.filter) {
      query.query.bool.filter = this.filterQuery.filter;
    }

    if (this.colFilterQuery && this.colFilterQuery.length) {
      query.query.bool.filter = [];
      this.colFilterQuery.forEach((obj: any) => {
        if (obj.bool && obj.bool.should && obj.bool.should[0].match) {
          query.query.bool.must.push(obj);
        } else {
          query.query.bool.filter.push(obj);
        }
      });
    }
    const q = JSON.stringify(query);
    const skip = this.reportJobcurrentPage;
    const limit = this.reportJobTableOptions.tableOptions.pageSize;
    let sort: any = [{}];
    if (this.reportJobTableOptions.sortOptions && this.reportJobTableOptions.sortOptions.direction
      && this.reportJobTableOptions.sortOptions.direction !== '') {
      const orderArr = ['job_data.task','job_data.status_message'];
      if (orderArr.indexOf(this.reportJobTableOptions.sortOptions.active) > -1) {
        sort[0][this.reportJobTableOptions.sortOptions.active + '.keyword'] = {order: this.reportJobTableOptions.sortOptions.direction};
      } else {
        sort[0][this.reportJobTableOptions.sortOptions.active] = {order: this.reportJobTableOptions.sortOptions.direction};
      }
    }
    sort = JSON.stringify(sort);
    const fields = JSON.stringify(['c', 'u', 'job_data.job_status', 'job_data.report_name', 'job_data.report_type', '_id',
      'job_data.task','job_data.status_message','task_id', 'job_data.report_job_message']);
    // @ts-ignore
    this.jobsService.getAllApiJobsGet({q, skip, limit, sort}).subscribe((result: any) => {
      this.loaderService.display(false);
      if (result && result.data.length) {
        this.reportJobTableOptions.pageData = result.data;
        this.reportJobTableOptions.tableOptions.pageTotal = result.total;
        this.reportJobsshowHideLoading(false);
      } else {
        this.reportJobTableOptions.pageData = [];
        this.reportJobTableOptions.tableOptions.pageTotal = 0;
        this.reportJobsshowHideLoading(false);
      }
    });
  }

  reportJobsglobalActionCall(idata: any): void {
    console.log(idata);
    if (idata.action.text === 'Terminate') {
      this.terminateJobs(idata);
    }
  }

  reportJobsshowHideLoading(status: boolean): void {
    const data = Object.assign({}, this.reportJobTableOptions);
    this.reportJobTableOptions = {};
    this.reportJobTableOptions = data;
    this.reportJobTableOptions.tableOptions.loading = status;
  }

  reportJobsactionCall(idata: any): void {
    if (idata.action.text === 'Details') {
      this.currentJob = undefined;
      setTimeout(() => { this.currentJob = idata.row; this.reportsnav.open();});
    } else if (idata.action.text === 'Terminate') {
      if (idata.row.job_data.job_status < 4) {
        this.terminateJob(idata.row);
      } else {
        this.toast.sToast('error', 'This job cannot be terminated');
      }
    }
    else if (idata.action.text === 'Retry') {
      if (idata.row.job_data.report_retries){
        if(idata.row.job_data.report_retries <= 5 && idata.row.job_data.job_status === 6) {
          this.retryJob(idata.row);
        } else {
          if (idata.row.job_data.job_status !== 5 && idata.row.job_data.job_status !== 6) {
            this.toast.sToast('error', 'Please Retry if job fails');
          }else if(idata.row.job_data.job_status === 5) {
            this.toast.sToast('error', ' Retry job is not allowed for successful job');
          }
        }
      } else if (idata.row.job_data.job_status === 6){
        this.retryJob(idata.row);
      }else {
        if (idata.row.job_data.job_status !== 5 && idata.row.job_data.job_status !== 6) {
          this.toast.sToast('error', 'Please Retry if job fails');
        }else if(idata.row.job_data.job_status === 5) {
          this.toast.sToast('error', ' Retry job is not allowed for successful job');
        }
      }
    } else if (idata.action.text === 'Download Report') {
      this.reportDownload(idata.row);
    }
  }

  terminateJobs(idata: any): void {
    const titleName = 'Confirmation';
    const message = 'Are you sure you want to terminate the selected job entries ?';
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe(res => {
      if (res) {
        this.loaderService.display(true, 'Terminating job....');
        idata.row.forEach((obj: any, index: number) => {
          if (obj.job_data.job_status < 4) {
            this.loaderService.display(true, `Terminating ${obj.job_data.task} job....`);
            this.baseService.doRequest(`api/jobs/${obj._id}/terminatejob`, 'post', {jobid: obj._id})
            .subscribe((result: any) => {
              this.loaderService.display(false);
              if (index === idata.row.length - 1) {
                this.loaderService.display(false);
                this.toast.sToast('success', result[1]);
                setTimeout(() => { this.getReportJobs(); }, 2000);
              }
            });
          } else {
            this.loaderService.display(false);
            this.toast.sToast('error', `${obj.job_data.task} job cannot be terminated`);
            if (index === idata.row.length - 1) {
              setTimeout(() => { this.getReportJobs(); }, 2000);
            }
          }
        });
      }
    });
  }


  terminateJob(idata: any): void {
    const titleName = 'Confirmation';
    const message = `Are you sure you want to terminate this ${idata.job_data.task} job ?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(true, 'Terminating job....');
        this.baseService.doRequest(`api/jobs/${idata._id}/terminatejob`, 'post', {jobid: idata._id})
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result[0]) {
              this.toast.sToast('success', result[1]);
              setTimeout(() => this.getReportJobs(), 2000);
            } else {
              this.toast.sToast('error', result[1]);
            }
          });
      }
    });
  }

  retryJob(idata: any): void {
    const titleName = 'Confirmation';
    const message = `Are you sure you want to retry this job ?`;
    const cancelText = 'No';
    const acceptText = 'Yes';
    this.confirmDialog.confirmDialog(titleName, message, cancelText, acceptText);
    this.confirmDialog.dialogResult.subscribe((res: any) => {
      if (res) {
        this.loaderService.display(true);
        this.baseService.doRequest(`api/standardreports/retryStandardReport`, 'post', {jobid: idata._id})
          .subscribe((result: any) => {
            this.loaderService.display(false);
            if (result[0]) {
              this.toast.sToast('success', result[1]);
              setTimeout(() => this.getReportJobs(), 2000);
            } else {
              this.toast.sToast('error', result[1]);
            }
          });
      }
    });
  }

  reportJobsfilterCall(event: any): void {
    const fields: any = [];
    this.reportJobTableOptions.columns.forEach((obj: any) => {
      fields.push(obj.columnDef);
    });
    this.filterQuery = (event && event.length > 0)
      ? {
        multi_match: {
          query: event, type: 'phrase_prefix',
          fields: [ 'job_data.task', 'job_data.status_message', 'agentRef.name']
        }
      } : undefined;
    if (this.comS.isUuid(event)) {
      this.filterQuery = { filter: [ {ids: {values: [event]}}] };
    }
    this.getReportJobs();
  }

  reportJobssortCall(event: any): void {
    this.reportJobTableOptions.sortOptions = event;
    this.getReportJobs();
  }

  reportJobspageCall(event: any): void {
    this.reportJobTableOptions.tableOptions.pageSize = event.pageSize;
    this.reportJobcurrentPage = event.pageIndex;
    this.getReportJobs();
  }

  colFilterCall(event: any): void {
    this.colFilterQuery = [];
    if (!this.colFilterCols.filter((x: any) => x.col === event.col).length) {
      if (event.value !== '') { this.colFilterCols.push(event); }
    } else {
      this.colFilterCols.forEach((obj: any, index: number) => {
        if (obj.col === event.col && event.value === '') {
          this.colFilterCols.splice(index, 1);
        } else if (obj.col === event.col) {
          obj.value = event.value;
        }
      });
    }
    this.colFilterCols.forEach((obj: any) => {
      const dateData = this.reportJobTableOptions.columns.filter((x: any) => x.columnDef === obj.col)[0];
      if (!dateData.dateFilter) {
        let qval:any;
        const char = /([\!\*\+\-\=\<\>\&\|\(\)\[\]\{\}\^\~\?\:\\/"g, '\\$1'])/;
        const searchValue=(typeof(obj.value)==='string') ? obj.value.trim():obj.value;
        if(obj.col=='job_data.job_status'){
          qval = (char.test(searchValue) && obj.col !== 'ip' && obj.col !== 'version') ? `\"${searchValue}\"` : '' + searchValue + '';
        }else{
          qval = (char.test(searchValue) && obj.col !== 'ip' && obj.col !== 'version') ? `\"${searchValue}\"` : '*' + searchValue + '*';
        }
        const tmpObj = { bool: { should: [{ query_string: { fields: [obj.col], query:qval} }] } };
        this.colFilterQuery.push(tmpObj);
      } else {
        const tmpObj = { bool: { must: [{ range: {} }] } };
        console.log(this.comS.getLastDay(0, dateData.filterData.end));
        // @ts-ignore
        tmpObj.bool.must[0].range[obj.col] = {
          'gte': new Date(dateData.filterData.start).toLocaleDateString('en-CA') + 'T00:00:00',
          'lte': new Date(dateData.filterData.end).toLocaleDateString('en-CA') + 'T23:59:00'
        };
        this.colFilterQuery.push(tmpObj);
      }
    });
    this.getReportJobs();
  }


  reportDownload(idata:any): void {
    this.loaderService.display(true, 'Report Download...');
      this.loaderService.display(false);
      const reqData: any = {
        jobid: idata._id
      };
      this.baseService.doRequest('/api/standardreports/downloadStandardReport', 'post', reqData).subscribe((res: any) => {
        if (res[0]){
          window.open(`${res[1]}`, '_blank');
        } else {
          this.toast.sToast('error', res[1]);
        }
      },(error: any) => {
        if (error.ok === false) {
          if (error.status === 403) {
            this.toast.sToast('error', 'Your role does not permit you to download the reports. Please contact your administrator to get the relevant permissions.');
          }
        }
      });
  }

  totalCallbackCheck($event: any): void { // @ts-ignore
    if (this[$event.id]) { // @ts-ignore
      this[$event.id].hideTable = ($event.value === 0);
    } else {
      console.log($event.id + ' not available');
    }
  }
}
