<div class="overflow-auto w-100">
  <mat-card class="mat-elevation-z0">
    <mat-card-header>
      <mat-card-title>
        Compliance Remediation
      </mat-card-title>
      <mat-card-subtitle>GPO policies can be used to ensure that all computers within an organization adhere to specific compliance requirements.</mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <ng-container *ngIf="loading">
        <span class="fs1 fw3 mr-2"> Loading...</span>
      </ng-container>
      <ng-container *ngIf="!loading">
        <table class="rounded-corners table w-70p">
          <thead>
            <tr>
              <th class="header-color"></th>
              <th class="header-color">Operating System</th>
              <th class="header-color">Download GPO Policy</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let gpo of gpoPolicies; let i = index;">
              <td width="5%"><img [src]="winLogo" class="providers-logo" [alt]="gpo.os"></td>
              <td>{{gpo.os}}</td>
              <td>
               <button  mat-raised-button color="primary" [disabled]="!authService.hasPermission('agent', 'complianceDownload')"
                        (click)="downloadPolicy(gpo) ">
                <mat-icon class="mat-18">file_download</mat-icon> Download
              </button></td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </mat-card-content>
  </mat-card>
</div>
