import {Component, Input, OnInit} from '@angular/core';
import {LoaderService} from '../../_services/loader.service';
import {BaseRequestService} from '../../_services/base.service';
import {ModalService} from "../../_services/modal.service";
import {ConfirmDialogService} from "../../_services/confirmdialog.service";
import {MyToastrService} from "../../_services/toastr.service";
import { AuthenticationService } from 'src/app/_services/authentication.service';

@Component({
  selector: 'app-compliance-remediation',
  templateUrl: './compliance-remediation.component.html',
  styleUrls: ['./compliance-remediation.component.scss']
})
export class ComplianceRemediationComponent implements OnInit {
  @Input() currentCompany: any;
  loading = true;
  winLogo = '/assets/images/os/windows.png';
  gpoPolicies: any = [];
  constructor(
    private loaderService: LoaderService, private baseService: BaseRequestService, private toast: MyToastrService,
    public authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.getGPOPolicies();
  }

  downloadPolicy(gpo: any): void {
    // window.open(gpo.link, '_blank');
    this.loaderService.display(true);
    
    this.baseService.doRequest(`/api/agent/dummy/complianceDownload`, 'post', { path: gpo.link})
      .subscribe((result: any) => {
      this.loaderService.display(false);
      if (result[0]) {
        window.open(result[1], '_blank');
      } else {
        this.toast.sToast('error', result[1]);
      }
    });
  }

  getGPOPolicies(): void {
    this.gpoPolicies = [
      {
        os: 'Windows 10', link: 'assets/compliance/GPO_Windows_10.zip'
      },
      {
        os: 'Windows 11', link: 'assets/compliance/GPO_Windows_11.zip'
      },
      {
        os: 'Windows Server 2012', link: 'assets/compliance/GPO_Windows_Server_2012.zip'
      },
      {
        os: 'Windows Server 2016', link: 'assets/compliance/GPO_Windows_Server_2016.zip'
      },
      {
        os: 'Windows Server 2019', link: 'assets/compliance/GPO_Windows_Server_2019.zip'
      },
      {
        os: 'Windows Server 2022', link: 'assets/compliance/GPO_Windows_Server_2022.zip'
      },
    ];
    setTimeout(() => this.loading = false, 2000);
  }

}
